import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ['enInput', 'deInput', 'frInput', 'itInput', 'localeNamePlaceholder']

  static values = {
    enName: String,
    deName: String,
    frName: String,
    itName: String
  }

  showEn(e) {
    e.preventDefault();

    this.enInputTarget.parentElement.classList.remove('d-none');
    this.deInputTarget.parentElement.classList.add('d-none');
    this.frInputTarget.parentElement.classList.add('d-none');
    this.itInputTarget.parentElement.classList.add('d-none');

    this.localeNamePlaceholderTarget.text = this.enNameValue;
  }

  showDe(e) {
    e.preventDefault();

    this.deInputTarget.parentElement.classList.remove('d-none');
    this.enInputTarget.parentElement.classList.add('d-none');
    this.frInputTarget.parentElement.classList.add('d-none');
    this.itInputTarget.parentElement.classList.add('d-none');

    this.localeNamePlaceholderTarget.text = this.deNameValue;
  }

  showFr(e) {
    e.preventDefault();

    this.frInputTarget.parentElement.classList.remove('d-none');
    this.deInputTarget.parentElement.classList.add('d-none');
    this.enInputTarget.parentElement.classList.add('d-none');
    this.itInputTarget.parentElement.classList.add('d-none');

    this.localeNamePlaceholderTarget.text = this.frNameValue;
  }

  showIt(e) {
    e.preventDefault();

    this.itInputTarget.parentElement.classList.remove('d-none');
    this.frInputTarget.parentElement.classList.add('d-none');
    this.deInputTarget.parentElement.classList.add('d-none');
    this.enInputTarget.parentElement.classList.add('d-none');

    this.localeNamePlaceholderTarget.text = this.itNameValue;
  }
}
