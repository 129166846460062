

export default function() {
    return {
    
        "en": {
            
            "timeline.editor_placeholder": "Enter your message...",
            
            "listings.listings_title": "Listings",
            
            "invoice.title": "Invoice",
            
            "scheduler.month": "Month",
            
            "scheduler.quarter": "Quarter",
            
            "scheduler.today": "Today",
            
            "scheduler.weeks": "Weeks",
            
            "scheduler.clear": "Clear",
            
            "stripe.persons.invalid_ssn_last_4": "Invalid format",
            
            "campaigns.details.tenant_selected": "Tenant selected",
            
            "campaigns.details.tenants_selected": "Tenants selected",
            
            "campaigns.details.sections.content.enter_message": "Enter your message",
            
            "date.formats.moment": "MM/DD/YYYY",
            
            "offers.controls.booking": "Create a booking",
            
            "offers.controls.offer": "Send offer",
            
            "offers.descriptions.booking": "Create a booking for walk-ins. Fill in the details and the total will be calculated. The payment between the landlord and tenant must be handled in person, either by cash or card. ",
            
            "offers.descriptions.offer": "Send an offer directly to your tenant. Fill in the details and the price will be calculated automatically. The tenant will receive the offer with a link to review, accept and pay it online.",
            
            "offers.title.booking": "Create a booking",
            
            "offers.title.offer": "Send an offer",
            
        },
    
        "de": {
            
            "timeline.editor_placeholder": "Gib eine Nachricht ein...",
            
            "listings.listings_title": "Inserate",
            
            "invoice.title": "Rechnung",
            
            "scheduler.month": "Monat",
            
            "scheduler.quarter": "Quartal",
            
            "scheduler.today": "Heute",
            
            "scheduler.weeks": "Wochen",
            
            "scheduler.clear": "löschen",
            
            "stripe.persons.invalid_ssn_last_4": "Ungültiges Format",
            
            "campaigns.details.tenant_selected": "Ausgewählter Mieter",
            
            "campaigns.details.tenants_selected": "Ausgewählte Mieter",
            
            "campaigns.details.sections.content.enter_message": "Nachricht eingeben",
            
            "date.formats.moment": "DD.MM.YYYY",
            
            "offers.controls.booking": "Erstelle eine Buchung",
            
            "offers.controls.offer": "Angebot senden",
            
            "offers.descriptions.booking": "Erstelle eine Buchung für Walk-ins. Fülle die Details aus und der Gesamtbetrag wird berechnet. Die Zahlung zwischen Vermieter und Mieter muss vor Ort abgewickelt werden, entweder in bar oder mit Karte. ",
            
            "offers.descriptions.offer": "Sende ein Angebot direkt an deinen Mieter. Fülle die Details aus und der Preis wird automatisch berechnet.  Der Mieter erhält das Angebot mit einem Link, über den er es online prüfen, annehmen und bezahlen kann.",
            
            "offers.title.booking": "Erstelle eine Buchung",
            
            "offers.title.offer": "Sende ein Angebot",
            
        },
    
        "de_formal": {
            
            "timeline.editor_placeholder": "Geben Sie eine Nachricht ein...",
            
            "listings.listings_title": "Inserate",
            
            "invoice.title": "Rechnung",
            
            "scheduler.month": "Monat",
            
            "scheduler.quarter": "Quartal",
            
            "scheduler.today": "Heute",
            
            "scheduler.weeks": "Wochen",
            
            "scheduler.clear": "löschen",
            
            "stripe.persons.invalid_ssn_last_4": "Ungültiges Format",
            
            "campaigns.details.tenant_selected": "Ausgewählter Mieter",
            
            "campaigns.details.tenants_selected": "Ausgewählte Mieter",
            
            "campaigns.details.sections.content.enter_message": "Nachricht eingeben",
            
            "date.formats.moment": "DD.MM.YYYY",
            
            "offers.controls.booking": "Erstellen Sie eine Buchung",
            
            "offers.controls.offer": "Angebot senden",
            
            "offers.descriptions.booking": "Erstellen Sie eine Buchung für Walk-ins. Füllen Sie die Details aus und der Gesamtbetrag wird berechnet. Die Zahlung zwischen Vermieter und Mieter muss vor Ort abgewickelt werden.",
            
            "offers.descriptions.offer": "Senden Sie ein Angebot direkt an Ihren Mieter. Füllen Sie die Details aus und der Preis wird automatisch berechnet.  Der Mieter erhält das Angebot mit einem Link, über den er es online prüfen, annehmen und bezahlen kann.",
            
            "offers.title.booking": "Erstelle eine Buchung",
            
            "offers.title.offer": "Senden Sie ein Angebot",
            
        },
    
        "fr": {
            
            "timeline.editor_placeholder": "Saisissez votre message...",
            
            "listings.listings_title": "Annonces",
            
            "invoice.title": "Facture",
            
            "scheduler.month": "Mois",
            
            "scheduler.quarter": "Trimestre",
            
            "scheduler.today": "Aujourd'hui",
            
            "scheduler.weeks": "Semaines",
            
            "scheduler.clear": "Clear",
            
            "stripe.persons.invalid_ssn_last_4": "Format non valide",
            
            "campaigns.details.tenant_selected": "Tenant selected",
            
            "campaigns.details.tenants_selected": "Tenants selected",
            
            "campaigns.details.sections.content.enter_message": "Enter your message",
            
            "date.formats.moment": "DD.MM.YYYY",
            
            "offers.controls.booking": "Créer une réservation",
            
            "offers.controls.offer": "Envoyer l'offre",
            
            "offers.descriptions.booking": "Créez une réservation de dernière minute. Remplissez les détails et le total sera calculé. Le paiement entre le propriétaire et le locataire doit être effectué en personne, soit en espèces, soit par carte.",
            
            "offers.descriptions.offer": "Envoyez une offre directement à votre locataire. Remplissez les détails et le prix sera calculé automatiquement. Le locataire recevra l'offre avec un lien de demande de paiement.",
            
            "offers.title.booking": "Créer une réservation",
            
            "offers.title.offer": "Envoyer une offre",
            
        },
    
        "it": {
            
            "timeline.editor_placeholder": "Scrivi un messaggio...",
            
            "listings.listings_title": "Inserzioni",
            
            "invoice.title": "Fattura",
            
            "scheduler.month": "Mese",
            
            "scheduler.quarter": "Trimestre",
            
            "scheduler.today": "Oggi",
            
            "scheduler.weeks": "Settimane",
            
            "scheduler.clear": "Cancella",
            
            "stripe.persons.invalid_ssn_last_4": "Formato non valido",
            
            "campaigns.details.tenant_selected": "Affittuario selezionato",
            
            "campaigns.details.tenants_selected": "Affittuari selezionati",
            
            "campaigns.details.sections.content.enter_message": "Scrivi un messaggio",
            
            "date.formats.moment": "DD/MM/YYYY",
            
            "offers.controls.booking": "Crea una prenotazione",
            
            "offers.controls.offer": "Inviare l'offerta",
            
            "offers.descriptions.booking": "Crea una prenotazione walk-in. Compila i dettagli per ottenere il calcolo del totale. Il pagamento tra locatore e affittuario deve essere effettuato di persona, in contanti o con carta.",
            
            "offers.descriptions.offer": "Invia un'offerta direttamente al tuo affittuario. Compila i dettagli e il prezzo verrà calcolato automaticamente. L'affittuario riceverà l'offerta con un link contenente la richiesta di pagamento.",
            
            "offers.title.booking": "Prenotazione confermata",
            
            "offers.title.offer": "Invia un'offerta",
            
        },
    
    };
};
