import { Controller } from "stimulus";
import getCsrfToken from "../utils/csrf";
import { initializeRadioButtons } from "../components/square-radio-button";
import { getDefaultHTML } from "../utils/trix-custom-toolbar";
import { t } from "../i18n/t";

export default class extends Controller {
  static targets = [
    "modal",
    "trix",
    "trixArea",
    "trixToolbarArea",
    "channelSelector",
    "channelHint",
    "listingsBlock",
    "building",
  ];
  static tenantsFetchTimer;
  static listingsFetchTimer;
  static validationTimer;

  connect() {
    initializeRadioButtons();
    this.initializeChannelSection();
    this.initializeListingsSection();
    this.initializeMessageEditor();
    this.initializeRecipientsSection();
    this.initializeScheduleSection();
    this.initializeControls();
    this.startValidationMonitor();
  }

  initializeChannelSection() {
    if (this.hasChannelSelectorTarget) {
      this.channelSelectorTarget.addEventListener("change", (event) => {
        const channelId = event.target.value;

        this.channelHintTarget.classList.remove("hidden");
        this.listingsBlockTarget.classList.add("hidden");
        this.buildingTarget.innerHTML = "";
        const listingsContainer = document.getElementById("listings_list");
        const listingIdsContainer = document.getElementById("campaign_listing_ids");
        listingsContainer.innerHTML = "";
        listingIdsContainer.value = "";

        if (channelId === "") return;

        fetch(`${this.buildingSelectorUrl}?channel_id=${channelId}`)
          .then((response) => response.text())
          .then((html) => {
            this.buildingTarget.innerHTML = html;
            this.channelHintTarget.classList.add("hidden");
            this.listingsBlockTarget.classList.remove("hidden");
          });
      });
    }
  }

  initializeListingsSection() {
    const listingsSearchInput = document.getElementById("campaign_listings");
    this.listingsFetchTimer = setInterval(() => {
      const buildingSelect = document.getElementById("campaign_building_id");
      const term = listingsSearchInput.getAttribute("data-term");
      const newTerm = listingsSearchInput.value;
      const buildingId = buildingSelect.getAttribute("data-building-id");
      const newBuildingId = buildingSelect.value;

      if (term !== newTerm || buildingId !== newBuildingId) {
        const searchUrl = listingsSearchInput.getAttribute("data-url");
        const channelId = document.querySelector("#campaign_channel_id").value;
        const listingIdsContainer = document.getElementById("campaign_listing_ids");
        let listingIds = listingIdsContainer.value;

        listingsSearchInput.setAttribute("data-term", newTerm);
        buildingSelect.setAttribute("data-building-id", newBuildingId);

        if (!newBuildingId) return;
        if (newBuildingId !== buildingId) {
          const listingsContainer = document.getElementById("listings_list");
          listingsContainer.innerHTML = "";
          listingIdsContainer.value = "";
          listingIds = "";
        }

        fetch(
          `${searchUrl}?term=${newTerm}&building_id=${newBuildingId}&listing_ids=${listingIds}&channel_id=${channelId}`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              "X-CSRF-Token": getCsrfToken(),
            },
          }
        )
          .then((response) => response.json())
          .then((data) => {
            this.renderListingsList(data);
          });
      }
    }, 700);
  }

  initializeRecipientsSection() {
    const tenantsSearchInput = document.getElementById("campaign_tenant");
    const tenantGroupSelect = document.getElementById("campaign_tenant_group_id");
    this.tenantsFetchTimer = setInterval(() => {
      const term = tenantsSearchInput.getAttribute("data-term");
      const newTerm = tenantsSearchInput.value;
      const tenantGroupId = tenantGroupSelect.getAttribute("data-tenant-group-id");
      const newTenantGroupId = tenantGroupSelect.value;
      if (term !== newTerm || tenantGroupId !== newTenantGroupId) {
        const searchUrl = tenantsSearchInput.getAttribute("data-url");
        const userIds = document.getElementById("campaign_user_ids").value;

        tenantsSearchInput.setAttribute("data-term", newTerm);
        tenantGroupSelect.setAttribute("data-tenant-group-id", newTenantGroupId);
        fetch(`${searchUrl}?term=${newTerm}&tenant_group_id=${newTenantGroupId}&user_ids=${userIds}`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            "X-CSRF-Token": getCsrfToken(),
          },
        })
          .then((response) => response.json())
          .then((data) => {
            this.renderTenantsList(data);
          });
      }
    }, 700);

    const selectAllCheckbox = document.querySelector("#recipients_section .header .select-all");
    selectAllCheckbox.addEventListener("click", () => {
      const checkboxes = document.querySelectorAll("#tenants_list .tenant-checkbox");
      const selectCounter = document.querySelector("#recipients_section .header .select-counter");

      selectAllCheckbox.classList.remove("some");
      if (selectAllCheckbox.classList.contains("all")) {
        selectCounter.innerHTML = t("campaigns.details.tenants_selected", this.locale());
        selectAllCheckbox.classList.remove("all");
        checkboxes.forEach((checkbox) => {
          checkbox.classList.remove("checked");
        });
      } else {
        this.updateTenantsCounter(checkboxes.length);

        selectAllCheckbox.classList.add("all");
        checkboxes.forEach((checkbox) => {
          checkbox.classList.add("checked");
        });
      }

      this.calculateCampaignUserIds();
    });
  }

  initializeScheduleSection() {
    let timer = setInterval(() => {
      const startAtInput = document.getElementById("campaign_start_at");
      const localizedStartAt = document.getElementById("localized_start_at");
      if (!localizedStartAt) {
        clearTimeout(timer);
        return;
      }

      startAtInput.value = localizedStartAt.getAttribute("aria-label");
      if (startAtInput.value) clearTimeout(timer);
    }, 100);
  }

  initializeControls() {
    const saveDraftLink = document.querySelector("#campaign_form .save-draft");
    saveDraftLink.addEventListener("click", () => {
      this.saveCampaign("draft");
    });

    const scheduleBtn = document.querySelector("#campaign_form .submit-btn");
    scheduleBtn.addEventListener("click", () => {
      this.saveCampaign("scheduled");
    });

    $(this.modalTarget).on("hidden.bs.modal", () => {
      clearTimeout(this.tenantsFetchTimer);
      clearTimeout(this.listingsFetchTimer);
      clearTimeout(this.validationTimer);
      this.modalTarget.remove();
    });
  }

  initializeMessageEditor() {
    Promise.all([import("trix/dist/trix")]).then(() => {
      const Trix = require("trix");
      Trix.config.toolbar.getDefaultHTML = getDefaultHTML;
      Trix.config.textAttributes.underline = {
        style: { textDecoration: "underline" },
        parser: (element) => {
          return element.style.textDecoration === "underline";
        },
        inheritable: 1,
      };

      this.trixToolbarAreaTarget.innerHTML = '<trix-toolbar id="campaign_message_toolbar"></trix-toolbar>';
      this.trixAreaTarget.innerHTML = `<trix-editor input="campaign_message"
                                                    toolbar="campaign_message_toolbar"
                                                    class="trix-content"
                                                    data-timeline-editor-target="trix"
                                                    role="textbox"
                                                    placeholder="${t(
                                                      "campaigns.details.sections.content.enter_message",
                                                      this.locale()
                                                    )}">                                                    
                                       </trix-editor>`;
    });

    const dropdownMenu = document.querySelector("#content_section .placeholders-dropdown .dropdown-menu-always-top");
    const dropdownLink = document.querySelector("#content_section .placeholders-dropdown .placeholder-link");
    dropdownLink.addEventListener("click", () => {
      if (dropdownLink.classList.contains("collapsed")) {
        dropdownLink.classList.remove("collapsed");
      } else {
        dropdownLink.classList.add("collapsed");
      }

      if (dropdownMenu.classList.contains("is-hidden")) {
        dropdownMenu.classList.remove("is-hidden");
      } else {
        dropdownMenu.classList.add("is-hidden");
      }
    });

    window.addEventListener("click", (event) => {
      if (!dropdownMenu.contains(event.target)) {
        dropdownMenu.classList.add("is-hidden");
        dropdownLink.classList.add("collapsed");
      }
    });

    const dropdownItems = document.querySelectorAll("#content_section .placeholders-dropdown .dropdown-item");
    dropdownItems.forEach((item) => {
      item.addEventListener("click", () => {
        const editor = this.trixAreaTarget.querySelector("trix-editor").editor;
        editor.insertString(item.getAttribute("data-value"));
        dropdownMenu.classList.add("is-hidden");
        dropdownLink.classList.add("collapsed");
      });
    });
  }

  startValidationMonitor() {
    const that = this;
    this.validationTimer = setInterval(() => {
      document
        .querySelectorAll(
          "#channel_section, #listings_section, #content_section, #recipients_section, #schedule_section"
        )
        .forEach((section) => {
          that.updateSectionIndicator(section);
        });
    }, 300);
  }

  renderListingsList(data) {
    const listingsContainer = document.getElementById("listings_list");
    listingsContainer.innerHTML = "";
    data.forEach((listing) => {
      const listingLine = this.buildListingLine(listing);
      listingsContainer.append(listingLine);
    });
    this.calculateCampaignListingIds();
  }

  buildListingLine(listing) {
    const listingLine = document.createElement("div");
    listingLine.classList.add("listing-line");
    listingLine.setAttribute("data-listing-id", listing.id);

    const listingInfo = document.createElement("div");
    listingInfo.classList.add("listing-info");

    const listingTitle = document.createElement("span");
    listingTitle.classList.add("listing-title");
    listingTitle.innerHTML = listing.title;
    listingInfo.append(listingTitle);

    const listingDescription = document.createElement("span");
    listingDescription.classList.add("listing-description");
    listingDescription.innerHTML = listing.description;
    listingInfo.append(listingDescription);

    const listingImage = document.createElement("div");
    listingImage.classList.add("listing-image");
    listingImage.style["display"] = "inline-block";
    if (listing.image_url) {
      listingImage.style["background"] = "url(" + listing.image_url + ")";
      listingImage.style["background-size"] = "contain";
    }

    listingLine.append(listingImage);
    listingLine.append(listingInfo);

    listingLine.addEventListener("click", () => {
      if (listingLine.classList.contains("selected")) return;

      this.selectListing(listingLine);
    });
    if (listing.selected) this.selectListing(listingLine);

    return listingLine;
  }

  selectListing(listingLine) {
    const listingRemoveBtn = document.createElement("i");
    listingRemoveBtn.classList.add("icon-close-light");
    listingRemoveBtn.classList.add("listing-remove");
    listingRemoveBtn.addEventListener("click", (event) => {
      event.stopPropagation();
      listingRemoveBtn.remove();
      listingLine.classList.remove("selected");
      this.calculateCampaignListingIds();
    });
    listingLine.classList.add("selected");
    listingLine.append(listingRemoveBtn);
    this.calculateCampaignListingIds();
  }

  calculateCampaignListingIds() {
    let listingIds = [];
    const selectedListingLines = document.querySelectorAll("#listings_list .listing-line.selected");
    selectedListingLines.forEach((listingLine) => {
      listingIds.push(listingLine.getAttribute("data-listing-id"));
    });

    const listingIdsContainer = document.getElementById("campaign_listing_ids");
    listingIdsContainer.value = listingIds;
  }

  renderTenantsList(data) {
    const tenantsListContainer = document.getElementById("tenants_list");
    tenantsListContainer.innerHTML = "";
    data.forEach((tenant) => {
      const checkbox = document.createElement("span");
      checkbox.classList.add("tenant-checkbox");
      checkbox.setAttribute("data-user-id", tenant.id);
      if (tenant.checked) checkbox.classList.add("checked");
      checkbox.addEventListener("click", () => {
        if (checkbox.classList.contains("checked")) {
          checkbox.classList.remove("checked");
        } else {
          checkbox.classList.add("checked");
        }

        this.calculateCampaignUserIds();
        this.renderRecipientsHeader();
      });

      const tenantInfo = document.createElement("div");
      tenantInfo.classList.add("tenant-info");

      const tenantName = document.createElement("span");
      tenantName.classList.add("tenant-name");
      tenantName.innerHTML = tenant.name;
      tenantInfo.append(tenantName);

      const tenantCompany = document.createElement("span");
      tenantCompany.classList.add("tenant-company");
      tenantCompany.innerHTML = tenant.company;
      tenantInfo.append(tenantCompany);

      const tenantAvatar = document.createElement("div");
      tenantAvatar.classList.add("organization");
      tenantAvatar.style["display"] = "inline-block";
      if (tenant.image_url) {
        tenantAvatar.style["background"] = "url(" + tenant.image_url + ")";
        tenantAvatar.style["background-size"] = "contain";
      } else {
        const placeholder = document.createElement("div");
        placeholder.classList.add("organization__placeholder");
        placeholder.innerHTML = tenant.initials;
        tenantAvatar.append(placeholder);
      }

      const tenantLine = document.createElement("div");
      tenantLine.classList.add("tenant-line");
      tenantLine.append(checkbox);
      tenantLine.append(tenantAvatar);
      tenantLine.append(tenantInfo);
      tenantsListContainer.append(tenantLine);
    });
    this.calculateCampaignUserIds();
    this.renderRecipientsHeader();
  }

  renderRecipientsHeader() {
    const tenantsListContainer = document.getElementById("tenants_list");

    const selectAllCheckbox = document.querySelector("#recipients_section .header .select-all");
    selectAllCheckbox.classList.remove("some");
    selectAllCheckbox.classList.remove("all");

    const checkboxes = tenantsListContainer.querySelectorAll(".tenant-checkbox");
    const checkedCheckboxes = tenantsListContainer.querySelectorAll(".tenant-checkbox.checked");
    if (checkboxes.length === checkedCheckboxes.length && checkedCheckboxes.length !== 0) {
      selectAllCheckbox.classList.add("all");
    } else {
      if (checkedCheckboxes.length > 0) {
        selectAllCheckbox.classList.add("some");
      }
    }

    this.updateTenantsCounter(checkedCheckboxes.length);
  }

  updateTenantsCounter(count) {
    const selectCounter = document.querySelector("#recipients_section .header .select-counter");
    switch (count) {
      case 0:
        selectCounter.innerHTML = t("campaigns.details.tenants_selected", this.locale());
        break;
      case 1:
        selectCounter.innerHTML = `${count} ${t("campaigns.details.tenant_selected", this.locale())}`;
        break;
      default:
        selectCounter.innerHTML = `${count} ${t("campaigns.details.tenants_selected", this.locale())}`;
    }
  }

  updateSectionIndicator(section) {
    const validatedInputs = Array.from(section.querySelectorAll('[data-validate="true"]'));
    const filled = validatedInputs.every((input) => {
      return !!input.value;
    });
    const sectionHeader = document.querySelector(`.section__header[data-target="#${section.getAttribute("id")}"]`);
    if (filled) {
      section.querySelectorAll(".modal__campaign .input__error").forEach((errorHolder) => {
        errorHolder.innerHTML = "";
        errorHolder.classList.add("is-hidden");
      });
      sectionHeader.classList.remove("invalid");
      sectionHeader.classList.add("filled");
    } else {
      sectionHeader.classList.remove("filled");
    }
  }

  calculateCampaignUserIds() {
    let userIds = [];
    const checkedCheckboxes = document.querySelectorAll("#tenants_list .tenant-checkbox.checked");
    checkedCheckboxes.forEach((checkbox) => {
      userIds.push(checkbox.getAttribute("data-user-id"));
    });

    const userIdsContainer = document.getElementById("campaign_user_ids");
    userIdsContainer.value = userIds;
  }

  saveCampaign(campaignStatus) {
    const form = document.getElementById("campaign_form");
    const method = form.getAttribute("data-method");
    const action = form.getAttribute("data-action");
    const unitId = document.querySelector("#campaign_unit_id").value;
    const senderId = document.querySelector("#campaign_sender_id").value;
    const buildingId = document.querySelector("#campaign_building_id").value;
    const channelId = document.querySelector("#campaign_channel_id").value;
    const title = document.querySelector("#campaign_title").value;
    const message = document.querySelector("#campaign_message").value;
    let startAt = document.querySelector("#campaign_start_at").value;
    startAt = this.dateTimeWithTimeZone(startAt);
    let userIds = document.querySelector("#campaign_user_ids").value;
    userIds = userIds ? userIds.split(",") : [null];
    let listingIds = document.querySelector("#campaign_listing_ids").value;
    listingIds = listingIds ? listingIds.split(",") : [null];

    $.ajax({
      url: action,
      type: method,
      dataType: "json",
      data: {
        campaign: {
          status: campaignStatus,
          unit_id: unitId,
          sender_id: senderId,
          building_id: buildingId,
          channel_id: channelId,
          title: title,
          start_at: startAt,
          message: message,
          user_ids: userIds,
          listing_ids: listingIds,
        },
      },
    }).done((data) => {
      if (data.errors) {
        this.showErrorNotifications(data.errors);
      } else {
        window.location.reload();
      }
    });
  }

  showErrorNotifications(errors) {
    document.querySelectorAll(".modal__campaign .input__error").forEach((errorHolder) => {
      errorHolder.innerHTML = "";
      errorHolder.classList.add("is-hidden");
    });

    document.querySelectorAll(".modal__campaign .accordion-section").forEach((section) => {
      section.classList.remove("invalid");
    });

    Object.entries(errors).forEach((error) => {
      const fieldName = error[0];
      const errorMessage = error[1];
      const errorHolder = document.getElementById(`campaign_${fieldName}_error`);
      const section = document.querySelector(`.accordion-section.${errorHolder.getAttribute("data-section")}`);
      section.classList.add("invalid");
      errorHolder.classList.remove("is-hidden");
      errorHolder.innerHTML = errorMessage;
    });
  }

  dateTimeWithTimeZone(dateTime) {
    if (!dateTime) return dateTime;

    const offset = (new Date().getTimezoneOffset() / 60) * -1;
    if (offset === 0) return dateTime;

    const sign = offset > 0 ? "+" : "-";
    return `${dateTime} ${sign}${offset}`;
  }

  locale() {
    return this.modalTarget.getAttribute("data-locale") || "en";
  }

  get buildingSelectorUrl() {
    return this.data.get("buildingSelectorUrl");
  }
}
