import { event } from "jquery"
import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ['detail', 'arrowDown','arrow', 'showTitle', 'showTemplate']
  static values = {url: String, more: String, less: String}

  connect(){
    if (document.querySelector('input[name="booking[brand_id]"]:checked')) { 
      let labelFor = document.querySelector('input[name="booking[brand_id]"]:checked').id
      let label = $(`label[for=${labelFor}]`)[1]
      label.classList.add('border-green-brand')
      label.classList.remove('border-gray-50')
    }
  }

  toggleBorder(event){
    let label = $(`label[for=${event.currentTarget.id}]`)[1]
    label.classList.toggle('border-green-brand')
    label.classList.toggle('border-gray-800')
    if(label.dataset.urlValue){
      let url  = label.dataset.urlValue
      $.ajax({url})
    }
  }

  toggleIncludeFees() {
    this.arrowDownTarget.classList.toggle('text-green-brand')
    this.arrowDownTarget.classList.toggle('text-gray-600')
    this.arrowTarget.classList.toggle('rotate-180')
  }

  toggleAccordian(event) {
    let active = this.detailTargets.find(d => d.open)
    if (active && active.contains(event.currentTarget)) {
      active.open = false
      event.preventDefault()
    }
    else if (!event.currentTarget.open && active) {
      active.open = false
    }
  }
  
  showMoreTemplates(){
    var feat = this.showTitleTarget
    if (feat.innerHTML.includes("less") || feat.innerHTML.includes('Weniger') || feat.innerHTML.includes('moins')) {
      feat.innerHTML = this.moreValue
    }
    else {
      feat.innerHTML = this.lessValue
    }
    this.showTemplateTargets.forEach(function(feature){
      if (feature.classList.contains("hidden-template")){
        feature.classList.toggle("hidden") 
      }
    })
  }
}
