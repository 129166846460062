import { Controller } from "stimulus";

import mapboxgl from "../../../../assets/javascripts/vendor/mapbox-gl";
import "../../../../assets/stylesheets/vendor/mapbox-gl.css";

export default class extends Controller {
  static targets = ["map", "mapData"];
  static values = { lat: Number, lng: Number, listingsScope: Boolean };

  connect() {
    this.mapData = JSON.parse(this.mapDataTarget.value).map_data;

    this.initMap();
  }

  initMap() {
    this.map = new mapboxgl.Map({
      accessToken: this.accessToken,
      container: this.mapTarget,
      center: [this.lngValue, this.latValue], // starting position [lng, lat]
      zoom: 12, // starting zoom
      scrollZoom: this.listingsScopeValue,
      cooperativeGestures: !this.listingsScopeValue,
    });

    this.map.addControl(new mapboxgl.NavigationControl());

    this.fitMap();
    this.map.on("load", () => {
      this.map.addSource("locations", {
        type: "geojson",
        data: this.mapData,
        cluster: true,
        clusterMaxZoom: 11, // Max zoom to cluster points on
        clusterRadius: 50, // Radius of each cluster when clustering points (defaults to 50)
      });

      this.map.addLayer({
        id: "clusters",
        type: "circle",
        source: "locations",
        filter: ["has", "point_count"],
        paint: {
          "circle-radius": 0,
        },
      });

      this.markers = {};
      this.markersOnScreen = {};

      this.map.on("idle", () => {
        if (!this.map.isSourceLoaded("locations")) return;
        this.updateMarkers();
      });
    });
  }

  onListingsAdded() {
    const newMapData = JSON.parse(this.mapDataTarget.value).map_data;
    this.mapData.features = [...this.mapData.features, ...newMapData.features];
    this.map.getSource("locations").setData(this.mapData);

    this.fitMap();
    this.updateMarkers();
  }

  updateMarkers() {
    const newMarkers = {};
    const exactLocationMatch = new Map();
    const offset = 0.00005; // small offset value for shifting markers

    const features = this.map.querySourceFeatures("locations");

    for (const feature of features) {
      let [lng, lat] = feature.geometry.coordinates;
      let key = `${lat},${lng}`;

      // if there are listings with the exact same location, we shift the marker up.
      if (exactLocationMatch.has(key)) {
        let count = exactLocationMatch.get(key);
        lat += offset * count;
        lng += offset * count;
        exactLocationMatch.set(key, count + 1);
      } else {
        exactLocationMatch.set(key, 1);
      }

      const coords = [lng, lat];
      const props = feature.properties;

      const id = props.cluster ? "cluster_" + props.cluster_id : props.id;
      let marker = this.markers[id];

      if (!marker) {
        const el = props.cluster ? this.createClusteredMarker(props, coords) : this.createPriceMarker(props);
        marker = this.markers[id] = new mapboxgl.Marker({
          element: el,
        }).setLngLat(coords);

        if (!props.cluster) {
          const infoBlock = `<a href="${props.url}" style="outline: none !important;">
                    <div class="info-window">
                      <div class="image-box">
                        <img src=${props.image}>
                      </div>
                      <div class="content">
                        <h4 class="title">${props.title}</h4>
                        <span>${props.buildingType}</span>
                      </div>
                      <p class="price">${props.details}</p>
                    </div>
                  </a>`;

          const popup = new mapboxgl.Popup({ offset: 25, closeButton: false }).setHTML(infoBlock);
          marker.setPopup(popup);
        }
      }

      newMarkers[id] = marker;

      if (!this.markersOnScreen[id]) marker.addTo(this.map);
    }
    // for every marker we've added previously, remove those that are no longer visible
    for (const id in this.markersOnScreen) {
      if (!newMarkers[id]) this.markersOnScreen[id].remove();
    }
    this.markersOnScreen = newMarkers;
  }

  createClusteredMarker(props, coords) {
    const el = document.createElement("div");
    el.className = "mapCluster";
    el.style.width = "36px";
    el.style.height = "36px";
    el.style.color = "#fff";
    el.style.textAlign = "center";
    el.style.fontSize = "16px";
    el.style.lineHeight = "32px";
    el.style.fontWeight = "800";
    el.style.display = "flex";
    el.style.justifyContent = "center";
    el.style.alignContent = "center";
    el.style.background = "var(--primary-color)";
    el.style.borderRadius = "50%";
    el.style.border = "2px solid #fff";
    el.innerText = props.point_count;

    const that = this;
    const clusterId = props.cluster_id;

    el.addEventListener("click", (event) => {
      that.map.getSource("locations").getClusterExpansionZoom(clusterId, (err, zoom) => {
        if (err) return;

        that.map.easeTo({
          center: coords,
          zoom: zoom,
        });
      });
    });

    return el;
  }

  createPriceMarker(props) {
    const el = document.createElement("div");
    el.style.border = "1px solid var(--primary-color)";
    el.style.backgroundColor = "#fff";
    el.style.padding = "10px 16px";
    el.style.borderRadius = "4px";
    el.style.cursor = "pointer";
    el.style.opacity = 1;
    el.appendChild(document.createTextNode(props.price));

    if (!this.listingsScopeValue) {
      let htmlString = `
        <p class="font-semibold">${props.property_name}</p>
        <p>${props.property_info}</p>
      `;
      el.innerHTML = htmlString.trim();
    }

    return el;
  }

  fitMap() {
    if (!this.mapData.features[0]) {
      return;
    }

    const bounds = this.map.getBounds();
    this.mapData.features.forEach((feature) => {
      bounds.extend(feature.geometry.coordinates);
    });

    this.map.fitBounds(bounds, {
      padding: 30,
    });
  }

  get accessToken() {
    return this.data.get("accessToken");
  }
}
