import { Controller } from "stimulus";
import { placesChanged } from "../events/places";
import { throttle } from "underscore";

export default class extends Controller {
  static targets = [
    "field",
    "map",
    "latitude",
    "longitude",
    "address1",
    "address2",
    "zip",
    "cityName",
    "areaName",
    "countryName",
    "countryCode",
    "viewport",
  ];

  static values = {
    country: String,
  };

  connect() {
    if (typeof google != "undefined") {
      this.initializeMap();
    }
  }

  initializeMap() {
    let latitude = this.latitudeTarget.value;
    let longitude = this.longitudeTarget.value;
    let position = new google.maps.LatLng(latitude, longitude);
    let autocompleteOptions = {};

    if (this.countryValue) {
      autocompleteOptions = {
        types: ["locality", "administrative_area_level_3", "sublocality_level_2", "shopping_mall"],
        componentRestrictions: { country: this.countryValue },
      };
    }

    this.autocomplete = new google.maps.places.Autocomplete(this.fieldTarget, autocompleteOptions);
    this.autocomplete.setFields(["address_components", "geometry", "icon", "name"]);
    this.autocomplete.addListener("place_changed", this.placeChanged.bind(this));

    if (this.hasMapTarget) {
      this.map = new google.maps.Map(this.mapTarget, {
        center: position,
        zoom: latitude == null ? 4 : 15,
      });
      this.autocomplete.bindTo("bounds", this.map);
      this.marker = new google.maps.Marker({
        map: this.map,
      });

      this.marker.setPosition(position);
      this.marker.setVisible(true);
    }

    this.fieldTarget.addEventListener("keydown", function (event) {
      if (event.key === "Enter") {
        event.preventDefault();
      }
    });

    if (this.enableDropdownUpdateOnScroll) {
      window.addEventListener("scroll", throttle(this.updateDropdownPosition.bind(this), 5));
    }
  }

  placeChanged() {
    let place = this.autocomplete.getPlace();

    if (!place.geometry) return;

    this.setAddressResults(place);

    if (this.hasMapTarget) {
      if (place.geometry.viewport) {
        this.map.fitBounds(place.geometry.viewport);
      } else {
        this.map.setCenter(place.geometry.location);
        this.map.setZoom(17);
      }

      this.marker.setPosition(place.geometry.location);
      this.marker.setVisible(true);
    }

    if (!this.disablePlaceChangedEvent) {
      placesChanged(place);
    }
  }

  keydown(event) {
    if (event.key === "Enter") {
      event.preventDefault();
    }
  }

  setAddressResults(result) {
    let street_number = "",
      address = "",
      level1 = "",
      level2 = "",
      level3 = "",
      locality = "",
      neighborhood = "",
      sublocality = "",
      sublocality_level_1 = "",
      country = "",
      country_code = "",
      postal_code = "",
      format = "";
    //console.log(result);
    for (let i = 0; i < result.address_components.length; i++) {
      const addr = result.address_components[i];
      if (addr.types[0] === "country") {
        country = addr.long_name;
        country_code = addr.short_name;
      } else if (addr.types[0] === "street_address") address = address + addr.long_name;
      else if (addr.types[0] === "route") address = address + addr.long_name;
      else if (addr.types[0] === "street_number") street_number = addr.long_name;
      else if (addr.types[0] === "administrative_area_level_1") level1 = addr.long_name;
      else if (addr.types[0] === "administrative_area_level_2") level2 = addr.short_name;
      else if (addr.types[0] === "administrative_area_level_3") level3 = addr.short_name;
      else if (addr.types[0] === "locality") locality = addr.long_name;
      else if (addr.types[0] === "postal_code") postal_code = addr.long_name;
      else if (addr.types[0] === "sublocality") sublocality = addr.short_name;
      else if (addr.types[0] === "sublocality_level_1") sublocality_level_1 = addr.short_name;
      else if (addr.types[0] === "neighborhood") neighborhood = addr.short_name;
    }

    this.latitudeTarget.value = result.geometry.location.lat();
    this.longitudeTarget.value = result.geometry.location.lng();
    this.viewportTarget.value = [
      result.geometry.viewport.getSouthWest().lat(),
      result.geometry.viewport.getSouthWest().lng(),
      result.geometry.viewport.getNorthEast().lat(),
      result.geometry.viewport.getNorthEast().lng(),
    ];

    if (this.hasAddress1Target) {
      this.address1Target.value = ("" + address + " " + street_number).trim();
    }

    if (this.hasAddress2Target) {
      this.address2Target.value = "";
    }

    if (this.hasZipTarget) {
      this.zipTarget.value = "" + postal_code;
    }

    if (this.hasCityNameTarget) {
      this.cityNameTarget.value = "" + locality || sublocality || level3 || level2;
    }

    if (this.hasCountryCodeTarget) {
      this.countryCodeTarget.value = "" + country_code;
    }

    if (this.hasCountryNameTarget) {
      this.countryNameTarget.value = "" + country;
    }
  }

  updateDropdownPosition() {
    const rect = this.fieldTarget.getBoundingClientRect();
    const dropdowns = Array.from(document.querySelectorAll(".pac-container")).filter(
      (dropdown) => dropdown.style.display !== "none"
    );

    if (dropdowns.length > 0) {
      const dropdown = dropdowns[0];
      dropdown.style.top = `${rect.bottom + window.scrollY}px`;
      dropdown.style.left = `${rect.left + window.scrollX}px`;

      for (let i = 1; i < dropdowns.length; i++) {
        dropdowns[i].style.display = "none";
      }
    }
  }

  get disablePlaceChangedEvent() {
    return this.data.get("disablePlaceChangedEvent") === "true";
  }

  get enableDropdownUpdateOnScroll() {
    return this.data.get("enableDropdownUpdateOnScroll") === "true";
  }
}
