import { Controller } from "stimulus";
import $ from "jquery";

export default class extends Controller {
  static targets = ["filter"];

  connect() {
    $(document).ready(() => {
      this.initializePageVisitDropdowns();
    });
  }

  initializePageVisitDropdowns() {
    $('.select2-dropdown.redirect').on('change', function() {
      var selectedOption = this.options[this.selectedIndex];
      if (selectedOption.value) {
        window.location.href = selectedOption.dataset.url;
      }
    });

    $(".select2-dropdown.redirect").each(function() {
      var $this = $(this);

      $this.select2({
        placeholder: $this.data('placeholder')
      }).on('select2:open', function() {
        $('input.select2-search__field').prop('placeholder', $this.data('search-bar-placeholder'));
      });
    });
  }
}
