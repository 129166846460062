import { Controller } from 'stimulus'
  
export default class extends Controller {

  static targets = ["field"]

  connect(){
    this.render =  this.render.bind(this)
    document.addEventListener('pos/places_changed', this.render, false);
  }

  fetch(){
    if (this.fieldTarget.value == '' && event.keyCode=='13') {
      this.render()
    }
  }

  render(){
    this.galleryController.load();
  }

  get galleryController() {
    return this.application.controllers.find(c => c.identifier === 'gallery')
  }
}
