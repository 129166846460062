import { Controller } from "stimulus"
import openModal from "../utils/remote-modal";

export default class extends Controller {
  connect() {
    this.injectedComponents = [];

    document.addEventListener(
      "turbolinks:before-cache",
      () => {
        this.teardown();
      },
      { once: true }
    );
  }

  inject(event) {
    const url = this.data.get('url');

    openModal(url, (id) => {
      this.injectedComponents.push(id);
    })
  }

  teardown() {
    this.injectedComponents.forEach(id => $('#' + id).remove())
  }
}
